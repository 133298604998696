export default [
    {
     name: 'Afganistán',
     id: 'AF'
    },
    {
     name: 'Albania',
     id: 'AL'
    },
    {
     name: 'Alemania',
     id: 'DE'
    },
    {
     name: 'Andorra',
     id: 'AD'
    },
    {
     name: 'Angola',
     id: 'AO'
    },
    {
     name: 'Anguila',
     id: 'AI'
    },
    {
     name: 'Antártida',
     id: 'AQ'
    },
    {
     name: 'Antigua y Barbuda',
     id: 'AG'
    },
    {
     name: 'Arabia Saudita',
     id: 'SA'
    },
    {
     name: 'Argelia',
     id: 'DZ'
    },
    {
     name: 'Argentina',
     id: 'AR'
    },
    {
     name: 'Armenia',
     id: 'AM'
    },
    {
     name: 'Aruba',
     id: 'AW'
    },
    {
     name: 'Australia',
     id: 'AU'
    },
    {
     name: 'Austria',
     id: 'AT'
    },
    {
     name: 'Azerbaiyán',
     id: 'AZ'
    },
    {
     name: 'Bahamas',
     id: 'BS'
    },
    {
     name: 'Bahrein',
     id: 'BH'
    },
    {
     name: 'Bailía de Guernsey',
     id: 'GG'
    },
    {
     name: 'Bangladesh',
     id: 'BD'
    },
    {
     name: 'Barbados',
     id: 'BB'
    },
    {
     name: 'Belarús',
     id: 'BY'
    },
    {
     name: 'Bélgica',
     id: 'BE'
    },
    {
     name: 'Belice',
     id: 'BZ'
    },
    {
     name: 'Benín',
     id: 'BJ'
    },
    {
     name: 'Bermudas',
     id: 'BM'
    },
    {
     name: 'Bolivia',
     id: 'BO'
    },
    {
     name: 'Bosnia y Hercegovina',
     id: 'BA'
    },
    {
     name: 'Botsuana',
     id: 'BW'
    },
    {
     name: 'Brasil',
     id: 'BR'
    },
    {
     name: 'Brunéi',
     id: 'BN'
    },
    {
     name: 'Bulgaria',
     id: 'BG'
    },
    {
     name: 'Burkina Faso',
     id: 'BF'
    },
    {
     name: 'Burundi',
     id: 'BI'
    },
    {
     name: 'Bután',
     id: 'BT'
    },
    {
     name: 'Cabo Verde',
     id: 'CV'
    },
    {
     name: 'Camboya',
     id: 'KH'
    },
    {
     name: 'Camerún',
     id: 'CM'
    },
    {
     name: 'Canadá',
     id: 'CA'
    },
    {
     name: 'Caribe Neerlandés',
     id: 'BQ'
    },
    {
     name: 'Catar',
     id: 'QA'
    },
    {
     name: 'Chad',
     id: 'TD'
    },
    {
     name: 'Chequia',
     id: 'CZ'
    },
    {
     name: 'Chile',
     id: 'CL'
    },
    {
     name: 'China',
     id: 'CN'
    },
    {
     name: 'Chipre',
     id: 'CY'
    },
    {
     name: 'Ciudad del Vaticano',
     id: 'VA'
    },
    {
     name: 'Colombia',
     id: 'CO'
    },
    {
     name: 'Comores',
     id: 'KM'
    },
    {
     name: 'Corea del Norte',
     id: 'KP'
    },
    {
     name: 'Corea del Sur',
     id: 'KR'
    },
    {
     name: 'Costa de Marfil',
     id: 'CI'
    },
    {
     name: 'Costa Rica',
     id: 'CR'
    },
    {
     name: 'Croacia',
     id: 'HR'
    },
    {
     name: 'Cuba',
     id: 'CU'
    },
    {
     name: 'Curaçao',
     id: 'CW'
    },
    {
     name: 'Dinamarca',
     id: 'DK'
    },
    {
     name: 'Dominica',
     id: 'DM'
    },
    {
     name: 'Ecuador',
     id: 'EC'
    },
    {
     name: 'Egipto',
     id: 'EG'
    },
    {
     name: 'El Salvador',
     id: 'SV'
    },
    {
     name: 'Emiratos Árabes Unidos',
     id: 'AE'
    },
    {
     name: 'Eritrea',
     id: 'ER'
    },
    {
     name: 'Eslovaquia',
     id: 'SK'
    },
    {
     name: 'Eslovenia',
     id: 'SI'
    },
    {
     name: 'España',
     id: 'ES'
    },
    {
     name: 'Estados Federados de Micronesia',
     id: 'FM'
    },
    {
     name: 'Estados Unidos de América',
     id: 'US'
    },
    {
     name: 'Estonia',
     id: 'EE'
    },
    {
     name: 'Esuatini',
     id: 'SZ'
    },
    {
     name: 'Etiopía',
     id: 'ET'
    },
    {
     name: 'Filipinas',
     id: 'PH'
    },
    {
     name: 'Finlandia',
     id: 'FI'
    },
    {
     name: 'Fiyi',
     id: 'FJ'
    },
    {
     name: 'Francia',
     id: 'FR'
    },
    {
     name: 'Gabón',
     id: 'GA'
    },
    {
     name: 'Gambia',
     id: 'GM'
    },
    {
     name: 'Georgia',
     id: 'GE'
    },
    {
     name: 'Georgia del Sur y las Islas Sandwich del Sur',
     id: 'GS'
    },
    {
     name: 'Ghana',
     id: 'GH'
    },
    {
     name: 'Gibraltar',
     id: 'GI'
    },
    {
     name: 'Granada',
     id: 'GD'
    },
    {
     name: 'Grecia',
     id: 'GR'
    },
    {
     name: 'Groenlandia',
     id: 'GL'
    },
    {
     name: 'Guadalupe',
     id: 'GP'
    },
    {
     name: 'Guam',
     id: 'GU'
    },
    {
     name: 'Guatemala',
     id: 'GT'
    },
    {
     name: 'Guayana',
     id: 'GY'
    },
    {
     name: 'Guayana Francesa',
     id: 'GF'
    },
    {
     name: 'Guinea',
     id: 'GN'
    },
    {
     name: 'Guinea Ecuatorial',
     id: 'GQ'
    },
    {
     name: 'Guinea-Bissau',
     id: 'GW'
    },
    {
     name: 'Haití',
     id: 'HT'
    },
    {
     name: 'Honduras',
     id: 'HN'
    },
    {
     name: 'Hong Kong',
     id: 'HK'
    },
    {
     name: 'Hungría',
     id: 'HU'
    },
    {
     name: 'India',
     id: 'IN'
    },
    {
     name: 'Indonesia',
     id: 'ID'
    },
    {
     name: 'Irán',
     id: 'IR'
    },
    {
     name: 'Iraq',
     id: 'IQ'
    },
    {
     name: 'Irlanda',
     id: 'IE'
    },
    {
     name: 'Isla Bouvet',
     id: 'BV'
    },
    {
     name: 'Isla de Man',
     id: 'IM'
    },
    {
     name: 'Isla de Navidad',
     id: 'CX'
    },
    {
     name: 'Isla de San Martín',
     id: 'MF'
    },
    {
     name: 'Isla Mauricio',
     id: 'MU'
    },
    {
     name: 'Isla Norfolk',
     id: 'NF'
    },
    {
     name: 'Islandia',
     id: 'IS'
    },
    {
     name: 'Islas Åland',
     id: 'AX'
    },
    {
     name: 'Islas Caimán',
     id: 'KY'
    },
    {
     name: 'Islas Cocos',
     id: 'CC'
    },
    {
     name: 'Islas Cook',
     id: 'CK'
    },
    {
     name: 'Islas Feroe',
     id: 'FO'
    },
    {
     name: 'Islas Heard y McDonald',
     id: 'HM'
    },
    {
     name: 'Islas Malvinas',
     id: 'FK'
    },
    {
     name: 'Islas Marianas del Norte',
     id: 'MP'
    },
    {
     name: 'Islas Marshall',
     id: 'MH'
    },
    {
     name: 'Islas Pitcairn',
     id: 'PN'
    },
    {
     name: 'Islas Salomón',
     id: 'SB'
    },
    {
     name: 'Islas Turcas y Caicos',
     id: 'TC'
    },
    {
     name: 'Islas Vírgenes (UK)',
     id: 'VG'
    },
    {
     name: 'Islas Vírgenes Americanas',
     id: 'VI'
    },
    {
     name: 'Israel',
     id: 'IL'
    },
    {
     name: 'Italia',
     id: 'IT'
    },
    {
     name: 'Jamaica',
     id: 'JM'
    },
    {
     name: 'Japón',
     id: 'JP'
    },
    {
     name: 'Jersey',
     id: 'JE'
    },
    {
     name: 'Jordania',
     id: 'JO'
    },
    {
     name: 'Kazajistán​​​',
     id: 'KZ'
    },
    {
     name: 'Kenia',
     id: 'KE'
    },
    {
     name: 'Kirguistán',
     id: 'KG'
    },
    {
     name: 'Kiribati',
     id: 'KI'
    },
    {
     name: 'Kosovo',
     id: 'XK'
    },
    {
     name: 'Kuwait',
     id: 'KW'
    },
    {
     name: 'Laos',
     id: 'LA'
    },
    {
     name: 'Lesotho',
     id: 'LS'
    },
    {
     name: 'Letonia',
     id: 'LV'
    },
    {
     name: 'Líbano',
     id: 'LB'
    },
    {
     name: 'Liberia',
     id: 'LR'
    },
    {
     name: 'Libia',
     id: 'LY'
    },
    {
     name: 'Liechtenstein',
     id: 'LI'
    },
    {
     name: 'Lituania',
     id: 'LT'
    },
    {
     name: 'Luxemburgo',
     id: 'LU'
    },
    {
     name: 'Macao',
     id: 'MO'
    },
    {
     name: 'Macedonia del Norte',
     id: 'MK'
    },
    {
     name: 'Madagascar',
     id: 'MG'
    },
    {
     name: 'Malasia',
     id: 'MY'
    },
    {
     name: 'Malaui',
     id: 'MW'
    },
    {
     name: 'Maldivas',
     id: 'MV'
    },
    {
     name: 'Malí',
     id: 'ML'
    },
    {
     name: 'Malta',
     id: 'MT'
    },
    {
     name: 'Marruecos',
     id: 'MA'
    },
    {
     name: 'Martinica',
     id: 'MQ'
    },
    {
     name: 'Mauritania',
     id: 'MR'
    },
    {
     name: 'Mayotte',
     id: 'YT'
    },
    {
     name: 'México',
     id: 'MX'
    },
    {
     name: 'Moldavia',
     id: 'MD'
    },
    {
     name: 'Mongolia',
     id: 'MN'
    },
    {
     name: 'Montenegro',
     id: 'ME'
    },
    {
     name: 'Montserrat',
     id: 'MS'
    },
    {
     name: 'Mozambique',
     id: 'MZ'
    },
    {
     name: 'Myanmar',
     id: 'MM'
    },
    {
     name: 'Namibia',
     id: 'NA'
    },
    {
     name: 'Nauru',
     id: 'NR'
    },
    {
     name: 'Nepal',
     id: 'NP'
    },
    {
     name: 'Nicaragua',
     id: 'NI'
    },
    {
     name: 'Níger',
     id: 'NE'
    },
    {
     name: 'Nigeria',
     id: 'NG'
    },
    {
     name: 'Niue',
     id: 'NU'
    },
    {
     name: 'Noruega',
     id: 'NO'
    },
    {
     name: 'Nueva Caledonia',
     id: 'NC'
    },
    {
     name: 'Nueva Zelandia',
     id: 'NZ'
    },
    {
     name: 'Omán',
     id: 'OM'
    },
    {
     name: 'Países Bajos',
     id: 'NL'
    },
    {
     name: 'Pakistán',
     id: 'PK'
    },
    {
     name: 'Palaos',
     id: 'PW'
    },
    {
     name: 'Palestina',
     id: 'PS'
    },
    {
     name: 'Panamá',
     id: 'PA'
    },
    {
     name: 'Papúa Nueva Guinea',
     id: 'PG'
    },
    {
     name: 'Paraguay',
     id: 'PY'
    },
    {
     name: 'Perú',
     id: 'PE'
    },
    {
     name: 'Polinesia Francesa',
     id: 'PF'
    },
    {
     name: 'Polonia',
     id: 'PL'
    },
    {
     name: 'Portugal',
     id: 'PT'
    },
    {
     name: 'Principado de Mónaco',
     id: 'MC'
    },
    {
     name: 'Puerto Rico',
     id: 'PR'
    },
    {
     name: 'Reino Unido',
     id: 'GB'
    },
    {
     name: 'República Centroafricana',
     id: 'CF'
    },
    {
     name: 'República del Congo',
     id: 'CG'
    },
    {
     name: 'República Democrática del Congo',
     id: 'CD'
    },
    {
     name: 'República Dominicana',
     id: 'DO'
    },
    {
     name: 'Reunión',
     id: 'RE'
    },
    {
     name: 'Ruanda',
     id: 'RW'
    },
    {
     name: 'Rumania',
     id: 'RO'
    },
    {
     name: 'Rusia',
     id: 'RU'
    },
    {
     name: 'Sáhara Occidental',
     id: 'EH'
    },
    {
     name: 'Samoa',
     id: 'WS'
    },
    {
     name: 'Samoa Americana',
     id: 'AS'
    },
    {
     name: 'San Bartolomé',
     id: 'BL'
    },
    {
     name: 'San Cristóbal y Nieves',
     id: 'KN'
    },
    {
     name: 'San Marino',
     id: 'SM'
    },
    {
     name: 'San Pedro y Miquelón',
     id: 'PM'
    },
    {
     name: 'San Vicente y las Granadinas',
     id: 'VC'
    },
    {
     name: 'Santa Elena, Ascensión y Tristán de Acuña',
     id: 'SH'
    },
    {
     name: 'Santa Lucía',
     id: 'LC'
    },
    {
     name: 'Santo Tomé y Príncipe',
     id: 'ST'
    },
    {
     name: 'Senegal',
     id: 'SN'
    },
    {
     name: 'Serbia',
     id: 'RS'
    },
    {
     name: 'Seychelles',
     id: 'SC'
    },
    {
     name: 'Sierra Leona',
     id: 'SL'
    },
    {
     name: 'Singapur',
     id: 'SG'
    },
    {
     name: 'Sint Maarten',
     id: 'SX'
    },
    {
     name: 'Siria',
     id: 'SY'
    },
    {
     name: 'Somalia',
     id: 'SO'
    },
    {
     name: 'Sri Lanka',
     id: 'LK'
    },
    {
     name: 'Sudáfrica',
     id: 'ZA'
    },
    {
     name: 'Sudán',
     id: 'SD'
    },
    {
     name: 'Sudán del Sur',
     id: 'SS'
    },
    {
     name: 'Suecia',
     id: 'SE'
    },
    {
     name: 'Suiza',
     id: 'CH'
    },
    {
     name: 'Surinam',
     id: 'SR'
    },
    {
     name: 'Svalbard y Jan Mayen',
     id: 'SJ'
    },
    {
     name: 'Tailandia',
     id: 'TH'
    },
    {
     name: 'Taiwán',
     id: 'TW'
    },
    {
     name: 'Tanzania',
     id: 'TZ'
    },
    {
     name: 'Tayikistán',
     id: 'TJ'
    },
    {
     name: 'Territorio Británico del Océano Índico',
     id: 'IO'
    },
    {
     name: 'Territorios Australes y Antárticos Franceses',
     id: 'TF'
    },
    {
     name: 'Timor Oriental',
     id: 'TL'
    },
    {
     name: 'Togo',
     id: 'TG'
    },
    {
     name: 'Tokelau',
     id: 'TK'
    },
    {
     name: 'Tonga',
     id: 'TO'
    },
    {
     name: 'Trinidad y Tobago',
     id: 'TT'
    },
    {
     name: 'Túnez',
     id: 'TN'
    },
    {
     name: 'Turkmenistán',
     id: 'TM'
    },
    {
     name: 'Turquía',
     id: 'TR'
    },
    {
     name: 'Tuvalu',
     id: 'TV'
    },
    {
     name: 'Ucrania',
     id: 'UA'
    },
    {
     name: 'Uganda',
     id: 'UG'
    },
    {
     name: 'Uruguay',
     id: 'UY'
    },
    {
     name: 'Uzbekistán',
     id: 'UZ'
    },
    {
     name: 'Vanuatu',
     id: 'VU'
    },
    {
     name: 'Venezuela',
     id: 'VE'
    },
    {
     name: 'Vietnam',
     id: 'VN'
    },
    {
     name: 'Wallis y Futuna',
     id: 'WF'
    },
    {
     name: 'Yemen',
     id: 'YE'
    },
    {
     name: 'Yibuti',
     id: 'DJ'
    },
    {
     name: 'Zambia',
     id: 'ZM'
    },
    {
     name: 'Zimbabue',
     id: 'ZW'
    }
   ]