<template>
  <div class="bgPanel rounded-md">
  <v-skeleton-loader class="rounded-md" v-bind="attrs" type="card-heading" />
    <v-col cols="6" class="pa-0 mb-2">
      <v-skeleton-loader class="document-skeleton rounded-md" v-bind="attrs" type="list-item-three-line" />
    </v-col>
    <v-skeleton-loader class="document-skeleton rounded-md" v-bind="attrs" type="actions" />
  </div>
</template>
<script>
export default {
  data: () => ({
    attrs: {
      boilerplate: true,
      elevation: 0
    }
  })
}
</script>